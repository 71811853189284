import React, { memo, useState, useEffect, useRef } from 'react';
import './PremiumModal.css';
import { useNavigate } from 'react-router-dom';

const PremiumModal = memo(({ 
  content, 
  onClose, 
  isAuthenticated = false, 
  isPremium = false 
}) => {
  const navigate = useNavigate();
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [error, setError] = useState(null);
  const videoRef = useRef(null);

  const goToPremium = () => {
    onClose();
    navigate('/premium');
  };

  const goToLogin = () => {
    onClose();
    navigate('/login');
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  const handleVideoLoad = () => {
    setIsVideoLoading(false);
  };

  const handleVideoError = () => {
    setIsVideoLoading(false);
    setError('Video yüklenemedi');
    console.error('Video loading failed');
  };

  return (
    <div
      className='modal-backdrop'
      onClick={onClose}
      role="dialog"
      aria-modal="true"
    >
      <div
        className='modal-content'
        onClick={(e) => e.stopPropagation()}
      >
        <h2>Premium İçerik</h2>
        
        {!isAuthenticated && (
          <>
            <p>Bu içeriği görüntülemek için giriş yapmalısınız.</p>
            <button
              className='login-button'
              onClick={goToLogin}
            >
              Giriş Yap
            </button>
          </>
        )}
        
        {isAuthenticated && !isPremium && (
          <>
            <p>Bu içeriği sadece premium kullanıcılar görüntüleyebilir!</p>
            <button
              className='premium-button'
              onClick={goToPremium}
            >
              Premium ol
            </button>
          </>
        )}
        
        {isAuthenticated && isPremium && content && (
          <>
            {content.contentType?.startsWith('image') && (
              <img
                src={`${content.filePath}`}
                alt={content.filename}
                loading="lazy"
                onError={() => setError('Resim yüklenemedi')}
              />
            )}
            
            {content.contentType?.startsWith('video') && (
              <>
                {isVideoLoading && <div className="loading-spinner">Video Yükleniyor...</div>}
                
                {error && (
                  <div className="error-message">
                    {error}
                    <button onClick={onClose}>Kapat</button>
                  </div>
                )}

                <video
                  ref={videoRef}
                  src={`${content.filePath}`}
                  controls
                  autoPlay
                  onLoadStart={() => setIsVideoLoading(true)}
                  onCanPlay={handleVideoLoad}
                  onError={handleVideoError}
                  style={{ display: isVideoLoading || error ? 'none' : 'block' }}
                />
              </>
            )}
          </>
        )}
        
        <button
          className='close-button'
          onClick={onClose}
          aria-label="Close modal"
        >
          Kapat
        </button>
      </div>
    </div>
  );
});

export default PremiumModal;