import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { register } from '../services/authService';
import './Register.css';
import Telegram from './Telegram';

const Register = () => {
	const [formData, setFormData] = useState({
		email: '',
		password: '',
		confirmPassword: '',
		token: '',
	});
	const [errors, setErrors] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [message, setMessage] = useState('');
	const [criteria, setCriteria] = useState({
		length: false,
		uppercase: false,
		lowercase: false,
		number: false,
		specialChar: false,
		noRepeatingSequences: true,
	});
  const [ isOpen, setIsOpen ] = useState(false);
  const [ showPassword, setShowPassword ] = useState(false);

	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const tokenFromUrl = queryParams.get('token');
		const emailFromUrl = queryParams.get('email');
		if (tokenFromUrl) {
			setFormData((prevState) => ({
				...prevState,
				token: tokenFromUrl,
				email: emailFromUrl || '',
			}));
		}
	}, [location]);

	useEffect(() => {
		validatePassword(formData.password);
	}, [formData.password]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const validatePassword = (password) => {
		const newCriteria = {
			length: password.length >= 6,
			uppercase: /[A-Z]/.test(password),
			lowercase: /[a-z]/.test(password),
			number: /\d/.test(password),
			specialChar: /[!@#$%-&_*.]/.test(password),
			noRepeatingSequences: !hasRepeatingSequences(password),
		};
		setCriteria(newCriteria);
  };

  const hasRepeatingSequences = (password) => {
		const maxRepeats = 2; 
		let currentChar = '';
		let repeatCount = 0;

		for (let i = 0; i < password.length; i++) {
			if (password[i] === currentChar) {
				repeatCount++;
				if (repeatCount > maxRepeats) {
					return true;
				}
			} else {
				currentChar = password[i];
				repeatCount = 1;
			}
		}

		return false;
  };

	const validate = () => {
		const errors = {};
		if (!formData.email) errors.email = 'Email is required';
		if (!formData.password) errors.password = 'Password is required';
		if (formData.password !== formData.confirmPassword) errors.confirmPassword = 'Şifreler eşleşmedi.';
		if (!formData.token) errors.token = 'Verification token is missing';
		return errors;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const validationErrors = validate();
		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
			return;
		}

		setIsSubmitting(true);
		setMessage('');
		setErrors({});

		try {
			const { confirmPassword, ...registerData } = formData;
			const response = await register(registerData);

			if (response && response.user) {
				setMessage('Kayıt Başarılı! Lütfen giriş yapın.');
				setTimeout(() => {
					navigate('/login');
				}, 2000);
			} else {
				throw new Error('Unexpected response from server');
			}
		} catch (error) {
			setMessage(error.response?.data?.error || error.message || 'Kayıt Başarısız. Lütfen tekrar deneyin!');
			if (error.response?.data?.errors) {
				setErrors(error.response.data.errors);
			}
		} finally {
			setIsSubmitting(false);
		}
  };

	return (
		<div className='register'>
			<h1>Kayıt Ol</h1>
			
			<div className="payment-info">
				<h2>🚨 Kayıt İşlemi Öncesi Önemli Bilgilendirme</h2>
				<p>Kayıt işlemine geçmeden önce aşağıdaki adımları tamamlamanız gerekmektedir:</p>
				<ol>
					<li><strong>Ödeme Yapma:</strong> Premium üyelik için ödemenizi yapmanız gerekiyor</li>
					<li><strong>Ödeme Onayı:</strong> Ödeme dekontu incelendikten sonra hesabınız aktifleştirilecektir</li>
					<li><strong>Hesap Oluşturma:</strong> Ödeme onayından sonra bu formla hesabınızı oluşturabilirsiniz</li>
				</ol>
				<p>Ödeme ve üyelik detayları için:</p>
				<a href="/premium" className="cta-button">PREMIUM Üyelik Detayları</a>
			</div>

			<form onSubmit={handleSubmit}>
				{/* Rest of the form remains the same */}
				<div className='form-group'>
					<label htmlFor='email'>E-posta</label>
					<input
						type='email'
						id='email'
						name='email'
						value={formData.email}
						onChange={handleChange}
						required
						disabled={!!formData.email}
					/>
					{errors.email && <p className='error'>{errors.email}</p>}
				</div>
				<div className='form-group'>
					<label htmlFor='password'>Şifre</label>
					<div className='showPasword'>
            <input
							onFocus={() => setIsOpen(true)}
							onBlur={() => setIsOpen(false)}
							type={showPassword ? 'text' : 'password'}
							id='password'
							name='password'
							value={formData.password}
							onChange={handleChange}
							required
						/>
						<span
							onMouseDown={() => setShowPassword(true)}
							onMouseUp={() => setShowPassword(false)}
							onMouseLeave={() => setShowPassword(false)}>
							<img
								src={`${
									showPassword ? '/images/eye-svgrepo-com.svg' : '/images/eye-off-svgrepo-com.svg'
								}`}
								alt=''
							/>
						</span>
					</div>

					{errors.password && <p className='error'>{errors.password}</p>}
				</div>
				<div className='form-group'>
					<label htmlFor='confirmPassword'>Şifreyi Onayla</label>
					<div className='showPasword'>
						<input
							type={showPassword ? 'text' : 'password'}
							id='confirmPassword'
							name='confirmPassword'
							value={formData.confirmPassword}
							onChange={handleChange}
							required
						/>
						<span
							onMouseDown={() => setShowPassword(true)}
							onMouseUp={() => setShowPassword(false)}
							onMouseLeave={() => setShowPassword(false)}>
							<img
								src={`${
									showPassword ? '/images/eye-svgrepo-com.svg' : '/images/eye-off-svgrepo-com.svg'
								}`}
								alt=''
							/>
						</span>
					</div>
					{errors.confirmPassword && <p className='error'>{errors.confirmPassword}</p>}
				</div>
				<div className='password-criteria'>
					<h3>Şifre Kriterleri:</h3>
					<ul className={`${!isOpen ? 'criteriasInvisible' : 'criteriasVisible'}`}>
						<li style={{ color: criteria.length ? 'green' : 'red' }}>
							En az 6 karakter {criteria.length ? '✔' : '✖'}
						</li>
						<li style={{ color: criteria.uppercase ? 'green' : 'red' }}>
							En az bir büyük harf {criteria.uppercase ? '✔' : '✖'}
						</li>
						<li style={{ color: criteria.lowercase ? 'green' : 'red' }}>
							En az bir küçük harf {criteria.lowercase ? '✔' : '✖'}
						</li>
						<li style={{ color: criteria.number ? 'green' : 'red' }}>
							En az bir rakam {criteria.number ? '✔' : '✖'}
						</li>
						<li style={{ color: criteria.specialChar ? 'green' : 'red' }}>
							En az bir özel karakter (örn. !,@,#,$,%,&,*,.,-_,) {criteria.specialChar ? '✔' : '✖'}
						</li>
						<li style={{ color: criteria.noRepeatingSequences ? 'green' : 'red' }}>
							Tekrar eden karakter kullanımı {criteria.noRepeatingSequences ? '✔' : '✖'}
						</li>
					</ul>
				</div>
				<input
					type='hidden'
					name='token'
					value={formData.token}
				/>
				<button
					type='submit'
					className='register-button'
					disabled={isSubmitting}>
					{isSubmitting ? 'Kaydediliyor...' : 'Kayıt Ol'}
				</button>
			</form>
			{message && <p className={message.includes('Başarılı') ? 'success-message' : 'error-message'}>{message}</p>}
			<Telegram />
		</div>
	);
};

export default Register;