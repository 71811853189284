import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from '../api/axios';
import './Payment.css';
import Telegram from './Telegram';

function Payment() {
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState(location.state?.amount || 350);
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage('');

    try {
      const response = await axios.post('/auth/initiate-payment', { email, amount });
      console.log('Server response:', response.data);
      setMessage(response.data.message);
    } catch (error) {
      console.error('Error details:', error);
      if (error.response) {
        setMessage(`Error: ${error.response.data.error || 'An unknown error occurred'}`);
      } else if (error.request) {
        setMessage('No response received from the server. Please try again.');
      } else {
        setMessage('An error occurred while sending the request. Please try again.');
      }
    }

    setIsSubmitting(false);
  };

  return (
    <div className="payment">
      <h1>Premium Üyelik İşlemleri</h1>
      
      <div className="process-info">
        <h2>🔥 Üyelik Süreci Nasıl İşliyor?</h2>
        <ol>
          <li>E-posta adresini gir ve planını seç</li>
          <li>Ödeme bilgileri e-postana gelecek</li>
          <li>Ödeme sonrası hesabın otomatik aktifleşecek</li>
        </ol>
      </div>

      <div className="payment-options">
        <h2>💳 Ödeme Seçenekleri</h2>
        <div className="payment-methods-grid">
          <div className="payment-method-card">
            <h3>💳 Kredi/Banka Kartı ile Ödeme</h3>
            <p>Güvenli ve hızlı kredi kartı veya banka kartı ödemesi</p>
            <div className="payment-links">
              <a 
                href="https://www.patreon.com/NudeCenneti" 
                target="_blank" 
                rel="noopener noreferrer"
                className="patreon-link"
              >
                <img 
                  src="https://c5.patreon.com/external/logo/become_a_patron_button@2x.png" 
                  alt="Patreon ile Öde" 
                  style={{width: '250px', height: 'auto'}}
                />
              </a>
              <a 
                href="https://www.patreon.com/NudeCenneti" 
                target="_blank" 
                rel="noopener noreferrer"
                className="credit-card-button"
              >
                <button className="payment-button credit-card">
                  Kredi/Banka Kartı ile Öde
                </button>
              </a>
            </div>
          </div>

          <div className="payment-method-card">
            <h3>📱 Papara ile Ödeme</h3>
            <p><strong>Papara Hesap No:</strong> 1982400478</p>
            <div className="warning-box">
              <p><strong>⚠️ DİKKAT:</strong> Ödeme yaparken açıklama kısmını boş bırakın!</p>
            </div>
          </div>

          <div className="payment-method-card">
            <h3>💎 Kripto Para ile Ödeme</h3>
            <p>Kripto ödeme için Telegram'dan iletişime geç</p>
            <a 
              href="https://t.me/nudecennetidestek" 
              target="_blank" 
              rel="noopener noreferrer"
              className="telegram-button"
            >
              <button className="payment-button telegram">
                Telegram'dan İletişime Geç
              </button>
            </a>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">E-posta Adresi:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="örnek@gmail.com"
          />
        </div>

        <div className="form-group">
          <label htmlFor="subscription">Abonelik Planı:</label>
          <select
            id="subscription"
            value={amount}
            onChange={(e) => setAmount(parseInt(e.target.value))}
            required
          >
            <option value={350}>1 Aylık Premium - 350 TRY</option>
            <option value={1750}>6 Aylık Premium - 1750 TRY</option>
            <option value={3500}>12 Aylık Premium - 3500 TRY</option>
          </select>
        </div>

        <button type="submit" className="submit-button" disabled={isSubmitting}>
          {isSubmitting ? 'İşleniyor...' : 'Ödeme Talimatlarını Al'}
        </button>
      </form>

      {message && <p className="message">{message}</p>}
      
      <Telegram />
    </div>
  );
}

export default Payment;