import { useEffect, useRef } from 'react';

const useEnhancedAntiTampering = () => {
  const originalStyles = useRef(new Map());
  const mutationObserver = useRef(null);
  const intervalRef = useRef(null);
  const videoElementsRef = useRef(new Set());
  
  useEffect(() => {
    let isInitialized = false;

    // Detect if user is on mobile device
    const isMobileDevice = () => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

    // Store and protect video elements
    const protectVideoElements = () => {
      const videoElements = document.querySelectorAll('video');
      
      videoElements.forEach(video => {
        if (!videoElementsRef.current.has(video)) {
          videoElementsRef.current.add(video);
          
          // Prevent direct access to video source
          video.addEventListener('loadedmetadata', () => {
            if (video.src.startsWith('blob:')) {
              return; // Allow blob URLs for streaming
            }
            video.src = ''; // Clear direct URLs
          });
          
          // Only apply certain protections on desktop
          if (!isMobileDevice()) {
            video.style.backfaceVisibility = 'hidden';
            video.style.webkitBackfaceVisibility = 'hidden';
            video.style.transform = 'translateZ(0)';
            video.disablePictureInPicture = true;
          }
          
          // Prevent seeking/timing attacks
          video.addEventListener('seeked', (e) => {
            const jumpThreshold = 10; // seconds
            if (Math.abs(video.currentTime - e.target._lastTime) > jumpThreshold) {
              video.currentTime = e.target._lastTime || 0;
            }
            e.target._lastTime = video.currentTime;
          });
        }
      });
    };

    // Enhanced style protection
    const storeOriginalStyles = () => {
      const protectedElements = document.querySelectorAll('.blurred, .premium-badge, .blur-overlay, video');
      originalStyles.current.clear();
      
      protectedElements.forEach(element => {
        const computedStyle = window.getComputedStyle(element);
        originalStyles.current.set(element, {
          filter: computedStyle.filter,
          display: computedStyle.display,
          visibility: computedStyle.visibility,
          opacity: computedStyle.opacity,
          position: computedStyle.position,
          pointerEvents: computedStyle.pointerEvents
        });
      });
    };

    // Verify element integrity with enhanced checks - mobile aware
    const verifyElementIntegrity = () => {
      if (!isInitialized || originalStyles.current.size === 0) return;

      const protectedElements = document.querySelectorAll('.blurred, video');
      
      protectedElements.forEach(element => {
        if (originalStyles.current.has(element)) {
          const currentStyle = window.getComputedStyle(element);
          const originalStyle = originalStyles.current.get(element);

          // Different checks for mobile vs desktop
          if (element.tagName.toLowerCase() === 'video') {
            if (!isMobileDevice() && (
                currentStyle.visibility !== originalStyle.visibility ||
                currentStyle.display !== originalStyle.display ||
                currentStyle.opacity !== originalStyle.opacity ||
                !element.disablePictureInPicture)) {
              window.location.reload();
            }
          } else {
            const hasBlur = currentStyle.filter.includes('blur') || 
                           currentStyle.webkitFilter?.includes('blur');
            
            if (!hasBlur || 
                currentStyle.display === 'none' || 
                currentStyle.visibility === 'hidden' || 
                currentStyle.opacity === '0') {
              window.location.reload();
            }
          }
        }
      });
    };

    // Enhanced mutation observer - mobile aware
    const setupMutationObserver = () => {
      mutationObserver.current = new MutationObserver((mutations) => {
        let shouldCheck = false;
        
        for (const mutation of mutations) {
          if (mutation.type === 'attributes') {
            const target = mutation.target;
            if (target.tagName.toLowerCase() === 'video' ||
                target.classList.contains('blurred') || 
                target.classList.contains('premium-badge') || 
                target.classList.contains('blur-overlay')) {
              shouldCheck = true;
              break;
            }
          }
          
          if (mutation.type === 'childList') {
            mutation.addedNodes.forEach(node => {
              if (node.tagName && node.tagName.toLowerCase() === 'video') {
                protectVideoElements();
              }
            });
            
            // Only check for removed nodes on desktop
            if (!isMobileDevice()) {
              for (const node of mutation.removedNodes) {
                if (node instanceof HTMLElement && 
                   (node.classList.contains('blurred') || 
                    node.classList.contains('premium-badge') || 
                    node.classList.contains('blur-overlay') ||
                    node.tagName.toLowerCase() === 'video')) {
                  window.location.reload();
                  return;
                }
              }
            }
          }
        }
        
        if (shouldCheck) {
          verifyElementIntegrity();
        }
      });

      mutationObserver.current.observe(document.body, {
        attributes: true,
        childList: true,
        subtree: true,
        attributeFilter: ['style', 'class', 'src']
      });
    };

    // Detect media recording APIs - desktop only
    const detectMediaRecording = () => {
      if (isMobileDevice()) return () => {};

      const originalGetDisplayMedia = navigator.mediaDevices?.getDisplayMedia;
      const originalMediaRecorder = window.MediaRecorder;

      if (navigator.mediaDevices) {
        navigator.mediaDevices.getDisplayMedia = function() {
          window.location.reload();
          return new Promise(() => {});
        };
      }

      if (window.MediaRecorder) {
        window.MediaRecorder = function() {
          window.location.reload();
          return new Promise(() => {});
        };
      }

      return () => {
        if (navigator.mediaDevices) {
          navigator.mediaDevices.getDisplayMedia = originalGetDisplayMedia;
        }
        window.MediaRecorder = originalMediaRecorder;
      };
    };

    // Initialize protection
    const initializeProtection = () => {
      setTimeout(() => {
        storeOriginalStyles();
        protectVideoElements();
        setupMutationObserver();
        isInitialized = true;
      }, 1000);
    };

    // Prevent right-click and keyboard shortcuts - desktop only
    const preventRightClick = (e) => {
      if (isMobileDevice()) return;
      
      const target = e.target;
      if (target.tagName.toLowerCase() === 'video' ||
          target.classList.contains('blurred') || 
          target.closest('.blur-overlay') || 
          target.closest('.video-container')) {
        e.preventDefault();
        return false;
      }
    };

    const preventShortcuts = (e) => {
      if (isMobileDevice()) return;
      
      if ((e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'i' || e.key === 'J' || e.key === 'j' || e.key === 'C' || e.key === 'c')) ||
          (e.ctrlKey && (e.key === 'U' || e.key === 'u')) ||
          e.key === 'F12') {
        e.preventDefault();
        return false;
      }
    };

    // Start monitoring - desktop only with relaxed thresholds
    const startDevToolsDetection = () => {
      if (isMobileDevice()) return;

      intervalRef.current = setInterval(() => {
        const threshold = 250; // Increased threshold for better compatibility
        const widthDiff = window.outerWidth - window.innerWidth;
        const heightDiff = window.outerHeight - window.innerHeight;
        
        // More sophisticated detection
        if ((widthDiff > threshold && heightDiff > 100) || // Both dimensions suspicious
            (widthDiff > threshold * 1.5) || // Very wide difference
            (heightDiff > threshold * 1.5)) { // Very tall difference
          window.location.reload();
        }
      }, 1000);
    };

    // Initialize all protections
    initializeProtection();
    startDevToolsDetection();
    const cleanupMediaRecording = detectMediaRecording();

    // Only add these listeners on desktop
    if (!isMobileDevice()) {
      document.addEventListener('contextmenu', preventRightClick);
      document.addEventListener('keydown', preventShortcuts);
    }

    // Cleanup
    return () => {
      isInitialized = false;
      mutationObserver.current?.disconnect();
      clearInterval(intervalRef.current);
      if (!isMobileDevice()) {
        document.removeEventListener('contextmenu', preventRightClick);
        document.removeEventListener('keydown', preventShortcuts);
      }
      cleanupMediaRecording();
      videoElementsRef.current.clear();
    };
  }, []);
};

export default useEnhancedAntiTampering;