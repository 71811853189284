import React, { useState, useEffect, useContext, useCallback, useMemo, useRef } from 'react';
import axios from 'axios';
import PremiumModal from './PremiumModal';
import { AuthContext } from '../context/authContext';
import { VideoSchema } from '../Schemas/VideoSchema';
import DynamicSEO from '../hooks/DynamicSEO';
import corpSchema from '../Schemas/corpSchema';
import useAntiTampering from '../hooks/useAntiTampering';
import Modal from './Modal';
import './Video.css';

const VideoComponent = ({ video, isAndroid, handleVideoPlay, handleVideoError, shouldBlur, handleVideoClick }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', () => {
        if (isAndroid) {
          videoRef.current.style.display = 'none';
          setTimeout(() => {
            videoRef.current.style.display = 'block';
          }, 0);
        }
      });
    }
  }, [isAndroid]);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (shouldBlur) {
      videoRef.current?.pause();
    }
    handleVideoClick(video);
  };

  const commonVideoProps = {
    ref: videoRef,
    className: `video-player ${shouldBlur ? 'blurred' : ''}`,
    poster: `${video.thumbnailUrl}?width=480&quality=85&t=${Date.now()}`,
    preload: "metadata",
    playsInline: true,
    controls: !shouldBlur,
    controlsList: "nodownload noplaybackrate",
    disablePictureInPicture: true,
    onPlay: () => {
      if (!shouldBlur) {
        handleVideoPlay(video._id);
        setIsPlaying(true);
      }
    },
    onError: () => handleVideoError(video._id),
    'data-video-id': video._id,
    muted: true,
    playsinline: "true",
    'webkit-playsinline': "true",
  };

  const androidProps = {
    ...commonVideoProps,
    'x5-video-player-type': "h5",
    'x5-video-player-fullscreen': "true",
    'x5-video-orientation': "portraint",
  };

  if (isAndroid) {
    return (
      <div className="android-video-container" onClick={handleClick}>
        <video {...androidProps}>
          <source src={video.sources[0].src} type="video/mp4" />
          Your browser does not support video playback.
        </video>
        {!isPlaying && (
          <div className="video-overlay">
            <span className="play-icon">▶</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div 
      className={`video-wrapper ${isPlaying ? 'playing' : ''}`} 
      onClick={handleClick}
    >
      {!isPlaying ? (
        <img
          src={`${video.thumbnailUrl}?width=480&quality=85&t=${Date.now()}`}
          alt="Video Thumbnail"
          className={`thumbnail ${shouldBlur ? 'blurred' : ''}`}
          onClick={() => {
            setIsPlaying(true);
            if (videoRef.current) videoRef.current.play();
          }}
        />
      ) : (
        <video {...commonVideoProps} autoPlay>
          <source src={video.sources[0].src} type="video/mp4" />
          {video.sources[1] && <source src={video.sources[1].src} type="video/webm" />}
          Your browser does not support video playback.
        </video>
      )}
      {!isPlaying && (
        <div className="video-overlay">
          <span className="play-icon">▶</span>
        </div>
      )}
    </div>
  );
};

function Video() {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const { isAuthenticated, isPremium } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isAndroid, setIsAndroid] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const videoRefs = useRef({});
  const videosRef = useRef(null);
  const observerRef = useRef(null);
  const modalRef = useRef(null);

  useAntiTampering();
  corpSchema();

  DynamicSEO({
    title: 'Nude Cenneti - Yetişkin İçerik Platformu',
    description:
      'Yetişkinlere özel sitemizde, yüksek kaliteli cinsel içerikler, erotik videolar ve görseller sunuyoruz. Güvenli, anonim ve hızlı erişimle istediğiniz her türlü erotik içeriğe kolayca ulaşabilirsiniz. Keşfedin, aradığınızı bulun.',
    keywords: 'cinsel içerik, yetişkin videoları, erotik görseller, yetişkinlere özel içerik, erotik videolar, porno, yetişkin filmleri, online erotik içerik',
    canonical: `${window.location.href}`,
  });
  

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /mobile|tablet|ipad|ipod|android/i.test(userAgent);
    const isAndroidDevice = /android/i.test(userAgent);
    
    setIsMobile(isMobileDevice);
    setIsAndroid(isAndroidDevice);
  }, []);

  const fetchVideos = useCallback(async () => {
    if (loading) {
      try {
        const response = await axios.get(
          `https://benudecenneti.com/api/media/video?page=${currentPage}`,
          {
            headers: {
              'Cache-Control': 'no-store',
              'Pragma': 'no-cache',
              'Expires': '0'
            },
            timeout: 8000
          }
        );

        const validatedVideos = response.data.videos
          .filter(video => 
            video?.contentType?.startsWith('video') && 
            video.filePath && 
            video.thumbnailUrl && 
            video._id
          )
          .map(video => ({
            ...video,
            thumbnailUrl: `${video.thumbnailUrl}?width=${isMobile ? 320 : 480}&quality=85&t=${Date.now()}`,
            sources: [
              { src: video.filePath, type: 'video/mp4' },
              { src: video.filePath.replace('.mp4', '.webm'), type: 'video/webm' }
            ]
          }));

        setVideos(validatedVideos);
        videosRef.current = validatedVideos;
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error('Error fetching videos:', error);
        setError('Failed to load videos. Please try again later.');
      } finally {
        setLoading(false);
      }
    }
  }, [currentPage, loading, isMobile]);

  useEffect(() => {
    fetchVideos();
  }, [fetchVideos]);

  useEffect(() => {
    if (isAndroid) return;

    const observerOptions = {
      root: null,
      rootMargin: '100px',
      threshold: 0.1
    };

    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const videoElement = entry.target;
          const videoId = videoElement.dataset.videoId;
          
          if (!videoElement.hasAttribute('src-loaded')) {
            const videoData = videosRef.current.find(v => v._id === videoId);
            if (videoData) {
              videoElement.innerHTML = '';
              const source = document.createElement('source');
              source.src = videoData.sources[0].src;
              source.type = 'video/mp4';
              videoElement.appendChild(source);

              if (!isAndroid && videoData.sources[1]) {
                const webmSource = document.createElement('source');
                webmSource.src = videoData.sources[1].src;
                webmSource.type = 'video/webm';
                videoElement.appendChild(webmSource);
              }

              videoElement.setAttribute('src-loaded', 'true');
              videoElement.load();
            }
          }
          observerRef.current?.unobserve(videoElement);
        }
      });
    };

    observerRef.current = new IntersectionObserver(handleIntersection, observerOptions);

    const currentVideoRefs = videoRefs.current;
    Object.values(currentVideoRefs).forEach(video => {
      if (video && !video.hasAttribute('src-loaded')) {
        observerRef.current.observe(video);
      }
    });

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [isAndroid]);

  const handleVideoPlay = useCallback((playingId) => {
    Object.entries(videoRefs.current).forEach(([id, video]) => {
      if (id !== playingId && video) {
        video.pause();
      }
    });
  }, []);

  const handleVideoError = useCallback((videoId) => {
    const video = videoRefs.current[videoId];
    if (video && videosRef.current) {
      const videoData = videosRef.current.find(v => v._id === videoId);
      if (videoData) {
        video.innerHTML = '';
        const source = document.createElement('source');
        source.src = videoData.sources[0].src;
        source.type = 'video/mp4';
        video.appendChild(source);
        video.load();
      }
    }
  }, []);

  const handleVideoClick = useCallback((video) => {
    if (!isAuthenticated || !isPremium) {
      setModalContent({ type: 'premium' });
    } else {
      setModalContent(video);
    }

    // Calculate modal position based on scroll and viewport
    const viewportHeight = window.innerHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const modalHeight = 400; // Approximate modal height
    
    const newPosition = {
      top: Math.max(scrollTop + (viewportHeight - modalHeight) / 2, scrollTop),
      left: '50%',
      transform: 'translateX(-50%)'
    };
    
    setModalPosition(newPosition);
    setIsModalOpen(true);
    
  }, [isAuthenticated, isPremium]);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
    document.body.style.overflow = '';
  }, []);

  const handlePageChange = useCallback((newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      setLoading(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [totalPages]);

  const renderPaginationButtons = useMemo(() => {
    const pageNumbers = [];
    const maxVisible = isMobile ? 3 : 5;
    const ellipsis = <span className="pagination-ellipsis">...</span>;

    if (totalPages <= maxVisible) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 2) {
        pageNumbers.push(1, 2, 3, ellipsis, totalPages);
      } else if (currentPage >= totalPages - 1) {
        pageNumbers.push(1, ellipsis, totalPages - 2, totalPages - 1, totalPages);
      } else {
        pageNumbers.push(1, ellipsis, currentPage, ellipsis, totalPages);
      }
    }

    return pageNumbers.map((number, index) => (
      <React.Fragment key={index}>
        {number === ellipsis ? (
          ellipsis
        ) : (
          <button
            onClick={() => handlePageChange(number)}
            className={`pagination-button ${currentPage === number ? 'active' : ''}`}
          >
            {number}
          </button>
        )}
      </React.Fragment>
    ));
  }, [totalPages, currentPage, handlePageChange, isMobile]);

  if (loading) {
    return (
      <div className="loading">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-[color:var(--primary-color)]"></div>
      </div>
    );
  }

  if (error) return <div className="error">{error}</div>;

  return (
    <div className="video">
      <h1>VIDEO GALERISI</h1>
      <div className="video-grid-container">
        <div className="video-grid">
          {videos.map((video) => (
            <div
              key={video._id}
              className="video-item"
            >
              <VideoComponent
                video={video}
                isAndroid={isAndroid}
                handleVideoPlay={handleVideoPlay}
                handleVideoError={handleVideoError}
                shouldBlur={!isAuthenticated || !isPremium}
                handleVideoClick={handleVideoClick}
              />
              {(!isAuthenticated || !isPremium) && (
                <span className="premium-badge">Premium</span>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-button prev"
        >
          Previous
        </button>
        {renderPaginationButtons}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="pagination-button next"
        >
          Next
        </button>
      </div>

      {isModalOpen && (
        <div 
          ref={modalRef}
          style={modalPosition}
          className="modal-container"
        >
          <PremiumModal
            content={modalContent}
            onClose={handleModalClose}
            isAuthenticated={isAuthenticated}
            isPremium={isPremium}
          />
        </div>
      )}
      
      <VideoSchema videos={videos} />
    </div>
  );
}

export default Video;