import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import axios from 'axios';
import PremiumModal from './PremiumModal';
import { AuthContext } from '../context/authContext';
import './Foto.css';
import './Pagination.css';
import DynamicSEO from '../hooks/DynamicSEO';
import corpSchema from '../Schemas/corpSchema';
import { ImageSchema } from '../Schemas/VideoSchema';
import useAntiTampering from '../hooks/useAntiTampering';

function Foto() {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isAuthenticated, isPremium } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [imageErrors, setImageErrors] = useState({});

  // Activate anti-tampering protection
  useAntiTampering();

  corpSchema();
  DynamicSEO({
    title: 'Nude Cenneti',
    description:
      'Yetişkinlere özel sitemizde, yüksek kaliteli cinsel içerikler, videolar ve görseller sunuyoruz. Güvenli, anonim ve hızlı erişim ile aradığınız her şeyi bulabilirsiniz.',
    keywords: 'cinsel içerik, yetişkin videoları, erotik görseller, yetişkinlere özel içerik, erotik videolar',
    canonical: `${window.location.href}`,
  });

  // Enhanced fetchPhotos with security measures
  const fetchPhotos = useCallback(async () => {
    if (loading) {
      try {
        const timestamp = Date.now();
        const response = await axios.get(`https://benudecenneti.com/api/media/foto?page=${currentPage}`, {
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0',
            'If-Modified-Since': new Date(timestamp).toUTCString()
          }
        });

        // Validate and sanitize response data
        const validatedPhotos = response.data.photos.filter(photo => 
          photo && 
          photo._id && 
          photo.filePath && 
          typeof photo.filePath === 'string'
        );

        setPhotos(validatedPhotos);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error('Error fetching photos:', error);
        setError('Failed to fetch photos. Please try again later.');
      } finally {
        setLoading(false);
      }
    }
  }, [currentPage, loading]);

  const handleImageError = useCallback((photoId) => {
    setImageErrors((prev) => ({ ...prev, [photoId]: true }));
    console.error(`Failed to load image for photo ID: ${photoId}`);
  }, []);

  useEffect(() => {
    fetchPhotos();

    // Security event listeners
    const preventDevToolsShortcut = (e) => {
      // Prevent common DevTools shortcuts
      if (
        (e.keyCode === 123) || // F12
        (e.ctrlKey && e.shiftKey && e.keyCode === 73) || // Ctrl+Shift+I
        (e.ctrlKey && e.shiftKey && e.keyCode === 74) || // Ctrl+Shift+J
        (e.ctrlKey && e.keyCode === 85) // Ctrl+U
      ) {
        e.preventDefault();
      }
    };

    const preventSaveImage = (e) => {
      if (e.target.tagName === 'IMG') {
        e.preventDefault();
      }
    };

    window.addEventListener('keydown', preventDevToolsShortcut);
    document.addEventListener('contextmenu', preventSaveImage);
    document.addEventListener('keydown', preventDevToolsShortcut);

    return () => {
      window.removeEventListener('keydown', preventDevToolsShortcut);
      document.removeEventListener('contextmenu', preventSaveImage);
      document.removeEventListener('keydown', preventDevToolsShortcut);
    };
  }, [fetchPhotos]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      setLoading(true);
      window.scrollTo(0, 0);
    }
  };

  const handlePhotoClick = useCallback((photo) => {
    if (isAuthenticated && isPremium) {
      setModalContent(photo);
    } else {
      setModalContent({ type: 'premium' });
    }
    setIsModalOpen(true);
  }, [isAuthenticated, isPremium]);

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const renderPaginationButtons = useMemo(() => {
    const pageNumbers = [];
    const ellipsis = <span className='pagination-ellipsis'>...</span>;

    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pageNumbers.push(1, 2, 3, 4, ellipsis, totalPages);
      } else if (currentPage >= totalPages - 2) {
        pageNumbers.push(1, ellipsis, totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        pageNumbers.push(1, ellipsis, currentPage - 1, currentPage, currentPage + 1, ellipsis, totalPages);
      }
    }

    return pageNumbers.map((number, index) => (
      <React.Fragment key={index}>
        {number === ellipsis ? (
          ellipsis
        ) : (
          <button
            onClick={() => handlePageChange(number)}
            className={`pagination-button ${currentPage === number ? 'active' : ''}`}>
            {number}
          </button>
        )}
      </React.Fragment>
    ));
  }, [totalPages, currentPage]);

  const renderedPhotos = useMemo(
    () =>
      photos.map((photo) => {
        const shouldBlur = !isAuthenticated || !isPremium;
        const imageUrl = `${photo.filePath}`;

        return (
          <div
            key={photo._id}
            className='photo-item'
            onClick={() => handlePhotoClick(photo)}>
            <div className='image-container'>
              <img
                src={imageUrl}
                alt={photo.filename.slice(0, -4)}
                className={shouldBlur ? 'blurred' : ''}
                onError={() => handleImageError(photo._id)}
                onDragStart={(e) => e.preventDefault()}
                onContextMenu={(e) => e.preventDefault()}
                loading="lazy"
                style={{
                  userSelect: 'none',
                  WebkitUserSelect: 'none',
                  WebkitTouchCallout: 'none',
                  pointerEvents: shouldBlur ? 'none' : 'auto'
                }}
              />
              {shouldBlur && (
                <div 
                  className='blur-overlay'
                  style={{
                    pointerEvents: 'none',
                    userSelect: 'none',
                    WebkitUserSelect: 'none'
                  }}
                >
                  <span className='premium-badge'>Premium</span>
                </div>
              )}
            </div>
          </div>
        );
      }),
    [photos, isAuthenticated, isPremium, handlePhotoClick, handleImageError],
  );

  if (loading) return <div className='loading'>Loading...</div>;
  if (error) return <div className='error'>{error}</div>;

  return (
    <div 
      className='foto'
      onCopy={(e) => e.preventDefault()}
      onCut={(e) => e.preventDefault()}
      onPaste={(e) => e.preventDefault()}
    >
      <h1>Fotoğraf Galerisi</h1>
      <div className='photo-grid'>{renderedPhotos}</div>

      {isModalOpen && (
        <PremiumModal
          content={modalContent}
          onClose={closeModal}
          isAuthenticated={isAuthenticated}
          isPremium={isPremium}
        />
      )}

      <div className='pagination'>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className='pagination-button'>
          Previous
        </button>
        {renderPaginationButtons}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className='pagination-button'>
          Next
        </button>
      </div>
      <ImageSchema images={photos} />
    </div>
  );
}

export default Foto;