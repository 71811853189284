import axios from 'axios';

const instance = axios.create({
	baseURL: 'https://benudecenneti.com',
	headers: {
		'Content-Type': 'application/json',
	},
});

instance.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem('token');
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}
		return config;
	},
	(error) => Promise.reject(error),
);

instance.interceptors.response.use(
	(response) => response,
	async (error) => {
		if (error.response) {
			console.error('Response error:', error.response.data);
			console.error('Status:', error.response.status);
		} else if (error.request) {
			console.error('Request error:', error.request);
		} else {
			console.error('Error:', error.message);
		}
		if (error.response && error.response.status === 401 && !error.config._retry) {
			localStorage.removeItem('token');
			window.location.href = '/login';
		}
		return Promise.reject(error);
	},
);

export default instance;