import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const FileUpload = () => {
	const [files, setFiles] = useState([]);
	const [uploadProgress, setUploadProgress] = useState({});
	const [isUploading, setIsUploading] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState('general');
	const [uploadComplete, setUploadComplete] = useState(false);
    const [ initialLoad, setInitialLoad ] = useState(true);
    const navigate = useNavigate();

	useEffect(() => {
		const token = localStorage.getItem('token');
		if (!token) {
			navigate('/');
			return;
		}

		axios
			.get('https://benudecenneti.com/admin', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((response) => {
				if (response.status !== 200 && response.data.role !== 'admin') {
					navigate('/');
				}
			})
			.catch((error) => {
				console.error('Kullanıcı bilgisi alınamadı', error);
				navigate('/');
			});
	}, [navigate]);


	// Chooose File
	const handleFileChange = (e) => {
		const selectedFiles = Array.from(e.target.files);
		setFiles(selectedFiles);
		setUploadProgress({});
	};

	// Category Changing
	const handleCategoryChange = (e) => {
		setSelectedCategory(e.target.value);
	};

	// File Upload
	const uploadFile = (file) => {
		const formData = new FormData();
		formData.append('file', file);

		return axios.post(`https://benudecenneti.com/api/media?category=${selectedCategory}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				setUploadProgress((prevProgress) => ({
					...prevProgress,
					[file.name]: percentCompleted,
				}));
			},
		});
	};

	// When Clicking Upload
	const handleUpload = async () => {
		setIsUploading(true);
		const uploadPromises = files.map((file) => {
			return uploadFile(file).catch(() => {
				alert('File upload failed');
			});
		});

		try {
			await Promise.all(uploadPromises);
		} finally {
			setIsUploading(false);
			setFiles([]);
		}
	};

	useEffect(() => {
		if (!initialLoad && Object.keys(uploadProgress).length === files.length) {
			const allUploaded = Object.values(uploadProgress).every((progress) => progress === 100);
			if (allUploaded) {
				setUploadComplete(true);
				setInitialLoad(true);
			}
		}
	}, [uploadProgress, files.length]);

	useEffect(() => {
		setInitialLoad(false);
	}, []);

	return (
		<div className='container mx-auto p-4'>
			<div className='bg-white shadow-md rounded-lg p-6'>
				<h2 className='text-xl font-bold mb-4'>Upload Files</h2>

				<div className='flex items-center gap-8'>
					<div className='mb-4 flex gap-2 items-center'>
						<label className='block text-gray-700 mb-2'>Select Category</label>
						<select
							value={selectedCategory}
							onChange={handleCategoryChange}
							className='border border-gray-300 rounded-lg p-2'>
							<option value='general'>General</option>
							<option value='foto'>Foto</option>
							<option value='video'>Video</option>
							<option value='anasayfa'>Anasayfa</option>
						</select>
					</div>

					<input
						type='file'
						multiple
						onChange={handleFileChange}
						className='mb-4'
					/>

					<button
						onClick={handleUpload}
						className={`bg-blue-500 text-white px-4 py-2 rounded-md ${
							isUploading ? 'opacity-50 cursor-not-allowed' : ''
						}`}
						disabled={isUploading}>
						{isUploading ? 'Uploading...' : 'Upload'}
					</button>
				</div>

				{files.length > 0 && (
					<ul className='mt-4 space-y-2'>
						{files.map((file, idx) => (
							<li
								key={idx}
								className='flex items-center justify-between'>
								<span className='w-1/4 overflow-hidden font-medium text-gray-700'>{file.name}</span>
								<div className='w-full bg-gray-200 rounded-lg h-2 ml-4'>
									<div
										className='bg-blue-500 h-2 rounded-lg'
										style={{ width: `${uploadProgress[file.name] || 0}%` }}
									/>
								</div>
								<span className='ml-2 text-sm'>{uploadProgress[file.name] || 0}%</span>
							</li>
						))}
					</ul>
				)}

				{uploadComplete && !isUploading && initialLoad && (
					<div className='mt-4 text-green-600 font-bold text-end'>All files uploaded successfully!</div>
				)}
			</div>
		</div>
	);
};

export default FileUpload;