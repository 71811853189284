import React, { useEffect, useRef, useState } from 'react';
import './Modal.css';

function Modal({ content, onClose, open }) {
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const isImage = content.contentType.startsWith('image');
  const isVideo = content.contentType.startsWith('video');

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (open) {
      document.addEventListener('keydown', handleKeyDown);

      if (isVideo && videoRef.current) {
        videoRef.current.play().catch(error => {
          console.error('Video play error:', error);
          setError('Video oynatılamadı');
        });
      }
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    };
  }, [open, content, isVideo, onClose]);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleVideoLoad = () => {
    setIsLoading(false);
  };

  const handleMediaError = (e) => {
    console.error('Media load error:', e);
    setError('İçerik yüklenemedi');
    setIsLoading(false);
  };

  return (
    <div 
      className='modal-backdrop' 
      onClick={onClose} 
      role="dialog"
      aria-modal="true"
    >
      <div 
        className='modal-content' 
        onClick={(e) => e.stopPropagation()}
      >
        {isLoading && <div className="loading-spinner">Yükleniyor...</div>}
        
        {error && (
          <div className="error-message">
            {error}
            <button onClick={onClose}>Kapat</button>
          </div>
        )}

        {isImage && !error && (
          <img
            src={`${content.filePath}`}
            alt={content.filename}
            loading="lazy"
            onLoad={handleImageLoad}
            onError={handleMediaError}
            style={{ display: isLoading ? 'none' : 'block' }}
          />
        )}
        
        {isVideo && !error && (
          <video
            ref={videoRef}
            src={`${content.filePath}`}
            controls
            autoPlay
            onLoadedMetadata={handleVideoLoad}
            onError={handleMediaError}
            style={{ display: isLoading ? 'none' : 'block' }}
          />
        )}
        
        <button 
          className='close-button' 
          onClick={onClose}
          aria-label="Close modal"
        >
          Kapat
        </button>
      </div>
    </div>
  );
}

export default Modal;