import { useEffect } from 'react';

const DynamicSEO = ({ title, description, keywords = [], canonical, noindex = false }) => {
  useEffect(() => {
    document.title = title;
    
    // Set basic meta tags
    setMetaTag('name', 'description', description);
    if (keywords.length > 0) {
      setMetaTag('name', 'keywords', Array.isArray(keywords) ? keywords.join(', ') : keywords);
    }

    // Set robots meta tag based on noindex prop
    setMetaTag('name', 'robots', noindex ? 'noindex, nofollow' : 'index, follow');

    // Set canonical URL
    setCanonical(canonical);

    // Cleanup function to remove meta tags when component unmounts
    return () => {
      // Remove the meta tags to avoid having duplicate entries on re-render
      cleanupMetaTags();
    };
  }, [title, description, keywords, canonical, noindex]);

  const setMetaTag = (attr, key, content) => {
    if (content) {
      let element = document.querySelector(`meta[${attr}="${key}"]`);
      if (!element) {
        element = document.createElement('meta');
        element.setAttribute(attr, key);
        document.head.appendChild(element);
      }
      element.setAttribute('content', content);
    }
  };

  const setCanonical = (href) => {
    let element = document.querySelector('link[rel="canonical"]');
    if (!element) {
      element = document.createElement('link');
      element.setAttribute('rel', 'canonical');
      document.head.appendChild(element);
    }
    if (href) {
      element.setAttribute('href', href);
    } else {
      element.parentElement?.removeChild(element);
    }
  };

  const cleanupMetaTags = () => {
    const metaTags = document.querySelectorAll('meta[name="description"], meta[name="keywords"], meta[name="robots"]');
    metaTags.forEach(tag => tag.parentElement.removeChild(tag));
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.parentElement.removeChild(canonicalLink);
    }
  };

  return null;
};

export default DynamicSEO;
