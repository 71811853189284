import React from 'react';
import './Hakkimizda.css';
import DynamicSEO from '../hooks/DynamicSEO';
import Telegram from './Telegram';

function Hakkimizda() {
  DynamicSEO({
    title: 'Hakkımızda - NudeCenneti',
    description:
      'NudeCenneti, premium yetişkin içeriklerle dolu arşivi ile sınırsız erişim sağlar. Üyelik seçeneklerimizi ve detayları keşfedin.',
    keywords: 'NudeCenneti, yetişkin içerik, premium içerik, fotoğraf, video, VIP üyelik',
  });

  const paymentMethods = [
    { method: "Papara ile ödeme", details: "1 Aylık 350 TL / 6 Aylık 1750 TL (Papara No: 1982400478)" },
  ];

  const warnings = [
    "Ödeme dekontu yalnızca hesaba geçtiğinde VIP üyelik tanımlanır.",
    "Sitemizde çalışmayan veya donan içerik yoktur, tüm içerikler düzenli olarak güncellenir.",
    "Destek almak için nazik olun ve yalnızca önemli konularda iletişim kurun."
  ];

  return (
    <div className="hakkimizda">
      <h1>Hakkımızda</h1>
      <p>NudeCenneti, internetin çeşitli bölgelerinden toplanan premium yetişkin içerikler sunan bir platformdur. Anasayfa hariç, tüm içeriklere erişim ücretlidir ve sınırsız görüntüleme fırsatı sunar.</p>
      <a href="/premium" className="cta-button">PREMIUM Üyelik Detayları</a>
      <p>PREMIUM üye olmak için <a href="/premium" className="highlight">buraya tıklayın</a>, ardından ödeme yaparak hesabınızı yükseltin.</p>

      <h2>PREMIUM Üyelik Ödeme Yöntemleri</h2>
      <ul>
        {paymentMethods.map((payment, index) => (
          <li key={index}><strong>{payment.method} –</strong> {payment.details}</li>
        ))}
      </ul>

      <h2>Önemli Bilgilendirme</h2>
      <ul>
        {warnings.map((warning, index) => (
          <li key={index}>{warning}</li>
        ))}
      </ul>

      <p>Sorularınız için <a href="mailto:isteknudecenneti@gmail.com">isteknudecenneti@gmail.com</a> adresine ulaşabilirsiniz. PREMIUM üyeliğiniz en kısa sürede aktif edilecektir.</p>
      <p>Telegram yoluyla canlı destek üzerinden çok hızlı bir şekilde iletişim sağlayabilirsiniz. Sağ alt tarafta bulunan TELEGRAM butonuna tıklamanız yeterli</p>
      <h2>Sistem Nasıl Çalışır?</h2>
      <p>Sitemize üye olup ödemenizi yaptıktan sonra VIP erişim elde edersiniz. Tüm içeriklere sınırsız erişim sağlanır.</p>

      <Telegram />
    </div>
  );
}

export default Hakkimizda;