import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './Anasayfa.css';
import Modal from './Modal';
import { ImageSchema, VideoSchema } from '../Schemas/VideoSchema';
import useCorpSchema from '../Schemas/corpSchema';
import DynamicSEO from '../hooks/DynamicSEO';
import Telegram from './Telegram';
import useAntiTampering from '../hooks/useAntiTampering';

const VideoComponent = React.memo(({ item, isAndroid, handleVideoPlay, handleVideoError }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', () => {
        if (isAndroid) {
          videoRef.current.style.display = 'none';
          setTimeout(() => {
            videoRef.current.style.display = 'block';
          }, 0);
        }
      });
    }
  }, [isAndroid]);

  if (isAndroid) {
    return (
      <div className="android-video-container">
        <video
          ref={videoRef}
          className={`w-full h-full object-cover ${item.premium ? 'blurred' : ''}`}
          poster={item.thumbnailUrl}
          preload="metadata"
          playsInline={true}
          controls
          controlsList="nodownload noplaybackrate"
          disablePictureInPicture
          onPlay={() => handleVideoPlay(item._id)}
          onError={() => handleVideoError(item._id)}
          data-video-id={item._id}
          muted
          playsinline="true"
          webkit-playsinline="true"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          x5-video-orientation="portraint"
        >
          <source src={item.sources[0].src} type="video/mp4" />
          Your browser does not support video playback.
        </video>
      </div>
    );
  }

  return (
    <video
      ref={videoRef}
      className={`video-player ${item.premium ? 'blurred' : ''}`}
      poster={item.thumbnailUrl}
      preload="none"
      playsInline
      controls
      controlsList="nodownload noplaybackrate"
      disablePictureInPicture
      onPlay={() => handleVideoPlay(item._id)}
      onError={() => handleVideoError(item._id)}
      data-video-id={item._id}
      muted
      playsinline="true"
      webkit-playsinline="true"
    >
      Your browser does not support video playback.
    </video>
  );
});

function Anasayfa() {
  const [content, setContent] = useState({ images: [], videos: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [visibleImages, setVisibleImages] = useState(12);
  const [visibleVideos, setVisibleVideos] = useState(8);
  const [isMobile, setIsMobile] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);

  const videoRefs = useRef({});
  const observerRef = useRef(null);
  const contentRef = useRef(content);

  useAntiTampering();
  useCorpSchema();

  DynamicSEO({
    title: 'Nude Cenneti',
    description:
      'Yetişkinlere özel nudecenneti.com sitemizde, yüksek kaliteli cinsel içerikler, videolar ve görseller sunuyoruz. Güvenli, anonim ve hızlı erişim ile aradığınız her şeyi bulabilirsiniz. Tek ihtiyacınız nude cenneti.',
    keywords:
      'porn,porno,porna,sex,sikiş,sikiş porno,nude, nude cenneti,nude foto,çıplak,foto, sex videoları, erotik foto, erotik videolar, erotik video,liseli ifşa,ifşa videoları,sex izle,porno izle,japon porno,zenci porno,lezbiyen sex,türbanlı sex,türbanlı porno,kürt porno,latin porno',
    canonical: `${window.location.href}`,
  });

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsMobile(/mobile|tablet|ipad|ipod|android/i.test(userAgent));
    setIsAndroid(/android/i.test(userAgent));
  }, []);

  const fetchContent = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`https://benudecenneti.com/api/media/anasayfa`, {
        headers: {
          'Cache-Control': 'no-store',
          'Pragma': 'no-cache',
          'Expires': '0'
        },
        timeout: 8000
      });

      const validatedVideos = response.data
        .filter(item => item?.contentType?.startsWith('video') && item.filePath && item.thumbnailUrl && item._id)
        .map(video => ({
          ...video,
          thumbnailUrl: `${video.thumbnailUrl}?width=${isMobile ? 320 : 480}&quality=85`,
          sources: [
            { src: video.filePath, type: 'video/mp4' },
            { src: video.filePath.replace('.mp4', '.webm'), type: 'video/webm' }
          ]
        }));

      const newContent = {
        images: response.data.filter(item => item?.contentType?.startsWith('image') && item.filePath && item._id),
        videos: validatedVideos
      };
      
      setContent(newContent);
      contentRef.current = newContent;
      setLoading(false);
    } catch (err) {
      setError('Content could not be loaded. Please try again later.');
      console.error('Content load error:', err);
      setLoading(false);
    }
  }, [isMobile]);

  useEffect(() => {
    fetchContent();
  }, [fetchContent]);

  useEffect(() => {
    if (isAndroid) return;

    const observerOptions = {
      root: null,
      rootMargin: '100px',
      threshold: 0.1
    };

    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const videoElement = entry.target;
          const videoId = videoElement.dataset.videoId;
          
          if (!videoElement.hasAttribute('src-loaded')) {
            const videoData = contentRef.current.videos.find(v => v._id === videoId);
            if (videoData) {
              const source = document.createElement('source');
              source.src = videoData.sources[0].src;
              source.type = 'video/mp4';
              videoElement.appendChild(source);

              if (!isAndroid && videoData.sources[1]) {
                const webmSource = document.createElement('source');
                webmSource.src = videoData.sources[1].src;
                webmSource.type = 'video/webm';
                videoElement.appendChild(webmSource);
              }

              videoElement.setAttribute('src-loaded', 'true');
              videoElement.load();
            }
          }
          observerRef.current?.unobserve(videoElement);
        }
      });
    };

    observerRef.current = new IntersectionObserver(handleIntersection, observerOptions);

    const currentVideoRefs = videoRefs.current;
    Object.values(currentVideoRefs).forEach(video => {
      if (video && !video.hasAttribute('src-loaded')) {
        observerRef.current.observe(video);
      }
    });

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [isAndroid]);

  const handleVideoPlay = useCallback((playingId) => {
    Object.entries(videoRefs.current).forEach(([id, video]) => {
      if (id !== playingId && video) {
        video.pause();
      }
    });
  }, []);

  const handleVideoError = useCallback((videoId) => {
    const video = videoRefs.current[videoId];
    if (video && contentRef.current.videos) {
      const videoData = contentRef.current.videos.find(v => v._id === videoId);
      if (videoData) {
        video.innerHTML = '';
        const source = document.createElement('source');
        source.src = videoData.sources[0].src;
        source.type = 'video/mp4';
        video.appendChild(source);
        video.load();
      }
    }
  }, []);

  const handleContentClick = useCallback((item) => {
    if (item.premium) {
      navigate('/premium');
    } else {
      setModalContent(item);
      setIsModalOpen(true);
    }
  }, [navigate]);

  if (loading) return (
    <div className="loading">
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-[color:var(--primary-color)]"></div>
    </div>
  );

  if (error) return (
    <div className="flex items-center justify-center h-screen text-red-500 px-4">
      <p>{error}</p>
    </div>
  );

  return (
    <div className="anasayfa">
      <h1 className="text-3xl font-bold text-center mb-4">Hoş geldiniz!</h1>
      <h2 className="text-3xl font-bold text-center mb-8">Ücretsiz içeriklerimize göz atın</h2>

      <section className="content-section">
        <h2>Fotoğraflar ({content.images.length})</h2>
        <div className="content-slider">
          {content.images.slice(0, visibleImages).map((item) => (
            <div
              key={item._id}
              className="content-item"
              onClick={() => handleContentClick(item)}
            >
              <img
                src={item.filePath}
                alt={item.filename?.slice(0, -4)}
                className={item.premium ? 'blurred' : ''}
                loading="lazy"
                onContextMenu={(e) => e.preventDefault()}
              />
              {item.premium && (
                <span className="premium-badge">Premium</span>
              )}
            </div>
          ))}
        </div>
        {visibleImages < content.images.length && (
          <button
            onClick={() => setVisibleImages(prev => prev + 12)}
            className="add-more-button"
          >
              Daha Fazla Yükle
          </button>
        )}
      </section>

      <section className="content-section">
        <h2>Videolar ({content.videos.length})</h2>
        <div className="content-slider">
          {content.videos.slice(0, visibleVideos).map((item) => (
            <div
              key={item._id}
              className="content-item"
              onClick={() => handleContentClick(item)}
            >
              <VideoComponent
                item={item}
                isAndroid={isAndroid}
                handleVideoPlay={handleVideoPlay}
                handleVideoError={handleVideoError}
              />
              {item.premium && (
                <span className="premium-badge">Premium</span>
              )}
            </div>
          ))}
        </div>
        {visibleVideos < content.videos.length && (
          <button
            onClick={() => setVisibleVideos(prev => prev + 8)}
            className="add-more-button"
          >
              Daha Fazla Yükle
          </button>
        )}
      </section>

      <Telegram />
      <ImageSchema images={content.images} />
      <VideoSchema videos={content.videos} />

      <div className="navigation">
        <Link to="/premium" className="nav-button">
          Premium İçerikler'e Göz At
        </Link>
      </div>

      {isModalOpen && (
        <Modal
          content={modalContent}
          onClose={() => setIsModalOpen(false)}
          open={isModalOpen}
        />
      )}
    </div>
  );
}

export default Anasayfa;